import React from 'react';
import Title from './title';

const AboutSection = () => {
  return (
    <section className="bg-black section" data-section="about">
      <div className="mx-4">
        <Title title="About Me" />
        <div className="max-w-2xl mx-auto px-2 text-white text-center lg:text-2xl mobile-landscape:-mt-0">
          <p className="">the husband of one wife</p>
          <p className="mb-10 mobile-landscape:mb-4">
            “Yo <span className="font-bold">Adriane</span>!”
          </p>
          <p className="">father of two girls</p>
          <p className="mb-10 mobile-landscape:mb-4">
            <span className="font-bold">Ava</span> who is 16 and <span className="font-bold">Presley</span> who is 10
            and is named after <span className="font-bold">Elvis</span>
          </p>
          <p className="max-w-2xl mx-auto">
            travels the country speaking at Churches, conferences, businesses, colleges and universities
          </p>
        </div>
      </div>
    </section>
  );
};

AboutSection.propTypes = {};

export default AboutSection;
