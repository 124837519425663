import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from "gatsby-plugin-image";

import { useStaticQuery, graphql } from 'gatsby';

const BackToTop = ({ className, fpApi }) => {
  const data = useStaticQuery(graphql`query BackToTopImages {
  arrow: file(relativePath: {eq: "arrow.png"}) {
    publicURL
    childImageSharp {
      gatsbyImageData(width: 150, quality: 100, layout: CONSTRAINED)
    }
  }
}
`);
  return (
    <div>
      <div
        className={`transition fixed z-10 w-full ${className}`}
        onClick={() => {
          console.log('clicking');
          console.log(fpApi.moveTo(1));
        }}
      >
        <div className="w-12 mx-auto cursor-pointer" >
          <GatsbyImage
            alt='arrow'
            image={data.arrow.childImageSharp.gatsbyImageData}
            />
        </div>
      </div>
    </div>
  );
};

BackToTop.propTypes = {
  className: PropTypes.string.isRequired,
  fpApi: PropTypes.any,
};

export default BackToTop;
