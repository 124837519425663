import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ title }) => {
  return (
    <div className="flex justify-around items-center mx-auto mb-10 mobile-landscape:hidden">
      <div className="border-b border-white w-full lg:mx-3"></div>
      <h1 className="text-white uppercase text-center text-2xl font-base grow whitespace-nowrap lg:text-5xl mx-6">
        {title}
      </h1>
      <div className="border-b border-white w-full lg:mx-3"></div>
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string,
};

Title.defaultProps = {
  title: '',
};

export default Title;
