import React, { useState } from 'react';
import ReactFullPage from '@fullpage/react-fullpage';
import Header from '../components/header';
import SEO from '../components/seo';
import HeroSection from '../components/heroSection';
import AboutSection from '../components/aboutSection';
import ContactSection from '../components/contactSection';
import BackToTop from '../components/backToTop';

// import Img from 'gatsby-image';
// import { useStaticQuery, graphql } from 'gatsby';

const IndexPage = () => {
  const [arrowClassName, setArrowClassName] = useState('hide');
  const [fpApi, setFPApi] = useState(null);
  return (
    <>
      <SEO title="Robert Kossak" />
      <Header />
      <main className={`font-body`}>
        <ReactFullPage
          licenseKey={'OPEN-SOURCE-GPLV3-LICENSE'}
          menu="#menu"
          anchors={['home', 'about', 'contact']}
          render={({ fullpageApi }) => {
            if (fullpageApi) {
              setFPApi(fullpageApi);
              if (
                fullpageApi.getActiveSection().anchor.toLowerCase() !== 'home'
              ) {
                setArrowClassName('show');
              } else {
                setArrowClassName('hide');
              }
            }
            return (
              <ReactFullPage.Wrapper>
                <HeroSection />
                <AboutSection />
                <ContactSection />
              </ReactFullPage.Wrapper>
            );
          }}
        />
        <BackToTop className={arrowClassName} fpApi={fpApi} />
      </main>
    </>
  );
};
export default IndexPage;
