import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";

import { useStaticQuery, graphql } from 'gatsby';
import Title from './title';

const ContactSection = () => {
  const data = useStaticQuery(graphql`query ContactInfo {
  site {
    siteMetadata {
      name
      social {
        email
      }
    }
  }
  email: file(relativePath: {eq: "email.png"}) {
    publicURL
    childImageSharp {
      gatsbyImageData(width: 150, quality: 100, layout: CONSTRAINED)
    }
  }
}
`);
  const currentYear = new Date().getFullYear();
  return (
    <section className="bg-black section" data-section="contact">
      <div className="mx-4">
        <Title title="Contact Me" />
        <div className="max-w-2xl mx-auto px-2 text-white text-center lg:text-2xl mobile-landscape:-mt-0">
          <div className="hidden lg:block">
            <p className="mt-10 mobile-landscape:mt-4">robert's speaking schedule has openings for</p>
            <p className="mb-10 mobile-landscape:mb-4 lg:mb-0">{currentYear} and he would love to hear from you!</p>
          </div>
          <div className="block lg:hidden">
            <p className="mobile-landscape:my-4 max-w-sm mx-auto">
              robert's speaking schedule has openings for {currentYear} and he would love to hear from you!
            </p>
          </div>
          <a
            href={`mailto:${data.site.siteMetadata.social.email}`}
            className="font-bold cursor-pointer mobile-landscape:hidden lg:block"
          >
            <div className="w-40 mx-auto">
              <GatsbyImage
                alt='email'
                image={data.email.childImageSharp.gatsbyImageData}
                />
            </div>
          </a>
          <a href={`mailto:${data.site.siteMetadata.social.email}`} className="font-bold cursor-pointer">
            {data.site.siteMetadata.social.email}
          </a>
          <p className="max-w-2xl mx-auto mt-10 mobile-landscape:mt-4 lg:mb-5">copyright {new Date().getFullYear()}</p>
          <div className="text-xs">
            <p>site designed and managed by</p>
            <a
              className="font-bold cursor-pointer"
              href="https://xeromedia.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              XeRo Media
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

ContactSection.propTypes = {};

export default ContactSection;
