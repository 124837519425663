import React from 'react';

const HeroSection = () => {
  return (
    <section className="bg-hero section" data-section="home">
      <div className="lg:hero-title">
        <div className="flex justify-around items-center max-w-6xl mx-auto">
          <div className="border-b border-white w-full mx-3 hidden lg:block"></div>
          <h1 className="text-orange-500 uppercase text-center text-4xl font-base grow whitespace-nowrap lg:text-5xl lg:mx-6">
            Robert Kossak
          </h1>
          <div className="border-b border-white w-full mx-3 hidden lg:block"></div>
        </div>
        <div className="text-2xl hidden lg:block">
          <p className="text-center text-white font-base">
            How then shall they call on Him in whom they have not believed?
          </p>
          <p className="text-center text-white font-base">
            And how shall they believe in Him of whom they have not heard?
          </p>
          <p className="text-center text-white font-base">
            And how shall they hear without a preacher?
          </p>
        </div>
        <p className="text-center text-white font-base lg:hidden">
          ... how shall they hear without a preacher?
        </p>
        <div className="uppercase text-white text-center font-bold lg:text-2xl">
          Romans 10:14 NKJV
        </div>
        <div className="mx-auto block bottom-0 absolute w-full pb-8">
          <div className="scroller-btn">
            <span className="scroller-ball"></span>
          </div>
        </div>
      </div>
    </section>
  );
};

HeroSection.propTypes = {};

export default HeroSection;
